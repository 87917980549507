import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UtilityService } from '../_services/utility.service';
import { Settings, AppSettings } from '../../app/app.settings';
import { first } from 'rxjs/operators';
import { ApiService } from '../_services/api-service';
//jno uncomment next 2 lines for sso
/* import { OAuthService, OAuthModule, AuthConfig, OAuthEvent } from 'angular-oauth2-oidc';
import { AuthService } from '../services/auth.service'; */
//import { OidcSecurityService } from 'angular-auth-oidc-client';
/* import { User } from 'oidc-client'; */
//import {HttpUrlEncodingCodec } from '@angular/common/http';
/* import 'rxjs/add/operator/filter'; */
//914
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  submitted = false;
  loading = false;
  /* loggingin = false;
  isMkreq = false;
  retryLogin: string; */
  returnUrl: string;
  public settings: Settings;
  // private userdata: string;
  forgotPasswordMode = false;
  badlogin: false;
  //backgroundImage = '../../assets/images/others/green-grass-lawn-139315.jpg';
  appid: string;
  // logoutAction: any;

  /* onNewSubmit(data) {
    console.warn("form data", data.pword);

    this.submitted = true;
    this.loading = true;
    //const f = this.loginForm.value;
    this.auth.login(data.uname, data.pword)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
          this.router.navigate(['/']);
        },
        error => {
          this.utilityService.alertError(error);
          this.loading = false;
        }
      );
  } */

  //jno uncomment authCodeFlowConfig2 for sso
  // public authCodeFlowConfig2: AuthConfig = {
  //   issuer: 'https://mk.marykayintouch.com',
  //   redirectUri: 'https://marykaystarprogram.com/signin-callback',
  //   clientId: '3MVG9mclR62wycM2HqFDbhd7SoC4wDiWH8iakEdkQIeoDntgKRsIDhuH5jKwSg9CE2MNAg3u.rrQX_xz7wZVY',
  //   responseType: 'code',
  //   scope: 'openid refresh_token',
  //   showDebugInformation: true,
  // };
  /* public authCodeFlowConfig: AuthConfig = {
    issuer: 'https://mk.marykayintouch.com',
    redirectUri: window.location.origin + '/signin-callback',
    postLogoutRedirectUri: window.location.origin + '/signin-callback',
    clientId: '3MVG9mclR62wycM2HqFDbhd7SoC4wDiWH8iakEdkQIeoDntgKRsIDhuH5jKwSg9CE2MNAg3u.rrQX_xz7wZVY',
    scope: 'openid offline_access',
    responseType: 'code',
    useSilentRefresh: true,
    showDebugInformation: true
  }; */
  constructor(
    //private api: ApiService,
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private utilityService: UtilityService,
    public appSettings: AppSettings,
    //private config: ConfigService,
    //jno uncomment next 2 lines for sso
    //private oauthService: OAuthService,
    //private _authService: AuthService,
    //public usr: User,
  ) {
    this.settings = this.appSettings.settings;
  };

  /* getData() {
    //const url ='https://jsonplaceholder.typicode.com/photos?albumId=1'
    //this.http.get(url).subscribe((res)=>{
    this.userdata = this.usr.access_token;
    console.log(this.userdata);
  } */

  ngOnInit() {

    /* if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('foo')
    }
 */
    //this.getData()
    //console.log(this.usr.access_token);

    // this.auth.logout();
    //jno Comment for sso
    this.utilityService.getEncryptKey();
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    /* this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]

    }); */

    //this.onSubmitVO();


    //jno - commenting this out while testing sso
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    /* this.route.queryParams
      .filter(params => params.returnUrl)
      .subscribe(params => {
        this.loggingin = true;
        this.isMkreq = true;
        console.log(params);
        this.appid = params.returnUrl;
        console.log(this.appid);
        if (this.appid && this.appid.length > 5) {
          //this.appid = this.httpCodec.decodeValue(this.appid);
          this.appid = decodeURIComponent(this.appid.substr(5));
          console.log(this.appid);
          this.api.post('Login/VerifyAppId', { appid: this.appid }).subscribe(data => {
            if (data) {
              this.onSubmitMK();
            }
            else {
              this.loggingin = false;
              this.isMkreq = false;
              this.router.navigate(['/']);
            }
          });
        }
        else {
          this.loggingin = false;
          this.isMkreq = false;
        }
      }); */

  }



  /*  //jno TESTING the auto login on MaxSite site with expected plain text params from MK Layer 7 API
   onSubmitLoginAuto() {
     this.submitted = true;
     this.loading = true;
     //jno pass in API data {username(aka: ConsultantNumber), contestTotal, subsidiaryCode, contestCode, starCount, bwhFlag, contestSubcode }
     this.auth.loginAuto("3909NX", 998, 'US', 'NLOS', 2, 'false', '21Q2')
       .pipe(first())
       .subscribe(
         data => {
           this.router.navigate([this.returnUrl]);
           //this.router.navigate(['/']);
         },
         error => {
           this.utilityService.alertError(error);
           this.loading = false;
         }
       );
   } */



  //jno Comment for sso
  onSubmitVO() {
    this.submitted = true;
    this.loading = true;
    const f = this.loginForm.value;
    this.auth.login(f.username, f.password)
      .pipe(first())
      .subscribe(
        data => {
          //this.router.navigate([this.returnUrl]);
          this.router.navigate(['/']);
        },
        error => {
          this.utilityService.alertError(error);
          this.loading = false;
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    const f = this.loginForm.value;
    this.auth.login(f.username, f.password)
      .pipe(first())
      .subscribe(
        data => {
          //this.router.navigate([this.returnUrl]);
          this.router.navigate(['/']);
        },
        error => {
          this.utilityService.alertError(error);
          this.loading = false;
        }
      );
  }

  //jno uncomment for sso
  /* onSubmitMK() {
    localStorage.setItem('mksso', 'Yes');
    this.oauthService.initCodeFlow();
  }

  logout() {
    this.oauthService.logOut();
  } */


  /* login() {
    this._authService.login();

    // private configureWithNewConfigApi() {
    //jno uncomment for sso
    if (this.oauthService.hasValidIdToken()) {
      return Promise.resolve(true);
    }

    this.oauthService.initCodeFlow();
    //this.oauthService.initLoginFlow();
    this.oauthService.configure(this.authCodeFlowConfig);
    //this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    //this.oauthService.getAccessToken();
  } */


  /* logoutMK() {
      //this.oauthService.configure(this.authCodeFlowConfig);
      this.oauthService.logOut();
    }
  
    test() {
      this.auth.askToStayLoggedIn();
    } */

}