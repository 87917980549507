import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { DataService } from '../_services/data.service';
import { Category } from '../app.models';
import { AuthenticationService } from '../_services/authentication.service';
import { LoginUser } from '../_models/loginuser';
import { Subscription } from 'rxjs';
import { CartService } from '../_services/cart.service';
import { ApiService } from 'src/app/_services/api-service';
import { filter } from 'rxjs/operators';
//914
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class PagesComponent implements OnInit, AfterViewInit {
  public showBackToTop = false;
  public categories: Category[];
  public category: Category;
  public sidenavMenuItems: Array<any>;
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('bwhNotice') bwhNotice: any;
  user: LoginUser;
  userSubscription: Subscription;
  public settings: Settings;
  greeting: string;
  availablePoints: string;
  backupWh: string;
  showMenu = true;
  ordersummarytop = {
    PointBalance: '',
    PointsEarned: '',
    PointsSpent: '',
    PointsPurchased: '',
    StarQuarters: '',
    OpenBalance: ''
  };
  previousUrl: string = null;
  currentUrl: string = null;
  constructor(
    public appSettings: AppSettings,
    public dataService: DataService,
    public router: Router,
    private auth: AuthenticationService,
    public cartService: CartService,
    private api: ApiService,
  ) {
    /* // On router event...
    this.router.events
      // ...if event is a navigation end and if target is home page...
      .pipe(filter(event =>
        event instanceof NavigationEnd && event.urlAfterRedirects === '/',
      ))
      // ...load data.
      .subscribe(() => this.load()); */
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
    });

    this.settings = this.appSettings.settings;
    this.userSubscription = this.auth.currentUser.subscribe((user: LoginUser) => {
      this.user = user;

      this.getPointHistorytop();
      // this.greeting = 'Hello, ' + user.firstName;
      //this.greeting = 'Available Credit Balance';

      /* if (user.balance > "0") {
        // this.availablePoints = 'You have ' + user.balance + ' Points';
        this.availablePoints = user.balance + ' Credits';
      } */

      if (user.BackupWithholding) {
        //this.backupWh = "jYes";
        this.bwhNotice.show();
      }
      else {
        //this.backupWh = "jNo";
        this.bwhNotice.hide();
      }


      if (this.previousUrl == "/checkout") {
        window.location.reload();
      }

    });
    // https://stackoverflow.com/questions/37332061/hide-elements-based-on-routing-in-angular2-rc1
    // !router.url.includes('account')
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('account')) {
          this.showMenu = false;
        } else {
          this.showMenu = true;
        }
      }
    });

    /* this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/') {
          window.location.reload();
          this.userSubscription = this.auth.currentUser.subscribe((user: any) => { this.user = user; })
           this.availablePoints = this.user.balance + ' Credits';
        }
      }
    }); */

  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
      }
    });
  }

  goToRecognition() {
    // for some reason when I put this in the link it wouldn't work unless you clicked it from the home page
    // [routerLink]="[ 'recognition']"
    this.router.navigate(['/recognition']);
  }

  public calcCredits(evt) {
    const val = evt.target ? evt.target.value : evt.value;
    const valscr = val.split(',').join('');
    const url = 'Generic/GetCalcCredits';
    const p = { Wholesale: valscr };

    if (valscr != "") {
      this.api.get(url, p)
        .subscribe((retval: any) => {
          if (val.includes(",")) {
            if (valscr > 209699) {
              document.getElementById('lblCredits').setAttribute("value", "570,005 +");
            }
            else {
              document.getElementById('lblCredits').setAttribute("value", retval.toLocaleString());
            }
          }
          else {
            if (valscr > 209699) {
              document.getElementById('lblCredits').setAttribute("value", "570005 +");
            }
            else {
              document.getElementById('lblCredits').setAttribute("value", retval);
            }
          }
        });
    }
    else {
      document.getElementById('lblCredits').setAttribute("value", "");
    }
    //document.getElementById('lblCredits').focus();
  }

  public calcCreditsmob(evt) {
    const val = evt.target ? evt.target.value : evt.value;
    const valscr = val.split(',').join('');
    const url = 'Generic/GetCalcCredits';
    const p = { Wholesale: valscr };

    if (valscr != "") {
      this.api.get(url, p)
        .subscribe((retval: any) => {
          if (val.includes(",")) {
            if (valscr > 209699) {
              document.getElementById('lblCreditsmob').setAttribute("value", "570,005 +");
            }
            else {
              document.getElementById('lblCreditsmob').setAttribute("value", retval.toLocaleString());
            }
          }
          else {
            if (valscr > 209699) {
              document.getElementById('lblCreditsmob').setAttribute("value", "570005 +");
            }
            else {
              document.getElementById('lblCreditsmob').setAttribute("value", retval);
            }
          }
        });
    }
    else {
      document.getElementById('lblCreditsmob').setAttribute("value", "");
    }
    //document.getElementById('lblCredits').focus();
  }

  public remove(product) {
    this.cartService.RemoveProduct(product);
  }
  public stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
  public search(evt) {
    // either get the search text from on enter or button pushed
    const val = evt.target ? evt.target.value : evt.value;
    this.router.navigate(['/generic', 0, val]);
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -window.pageYOffset / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.pageYOffset !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    $event.target.documentElement.scrollTop > 300
      ? (this.showBackToTop = true)
      : (this.showBackToTop = false);
  }

  public closeSubMenus() {
    if (window.innerWidth < 960) {

    }
  }

  getPointHistorytop() {
    const url = 'Recognition/AccountHistory';
    const p = { UserID: this.user.userID };
    this.api.get(url, p)
      .subscribe((retval: any) => this.ordersummarytop = retval);
  }

  /*  private load() {
     this.userSubscription = this.auth.currentUser.subscribe((user: any) => { this.user = user; })
     this.availablePoints = this.user.balance + ' Credits';
   } */


  public clear() {
  }
  /*
  public getCategories() {
    this.dataService.getCategories().subscribe(data => {
      this.categories = data;
      this.category = data[0];
      this.dataService.Data.categories = data;
    });
  }

  public changeCategory(event) {
    if (event.target) {
      this.category = this.categories.filter(
        category => category.name === event.target.innerText
      )[0];
    }
    if (window.innerWidth < 960) {
      this.stopClickPropagate(event);
    }
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }
*/
}
