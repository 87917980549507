import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpHandler,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { UtilityService } from '../_services/utility.service';
import { Router } from '@angular/router';
//914
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private utilityService: UtilityService,
    private router: Router
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0 ) {
            this.utilityService.spinner.hide();
            this.utilityService.alert(' Sorry, we are unable to connect to the server! Please email Contact IS at issupport@rymaxinc.com');
          }
          if (error.status === 401) {
            this.utilityService.spinner.hide();
            this.utilityService.alert(' Sorry, you are required to login!' );
            this.router.navigate(['/login']);
            return throwError(error.statusText);
          }
          if (error.status === 500) {
            this.utilityService.spinner.hide();
            //this.utilityService.alert(' Sorry, internal server error! Please email Contact IS at issupport@rymaxinc.com' );
            return throwError(error.statusText);
          }
          this.utilityService.spinner.hide();
          const applicationError = error.headers.get('ApplicationError');
          if (applicationError) {
            return throwError(applicationError);
          }
          const serverError = error.error;
          let modalStateErrors = '';
          if (serverError && typeof serverError === 'object') {
            for (const key in serverError) {
              if (serverError[key]) {
                modalStateErrors += serverError[key] + '\n';
              }
            }
          }
          return throwError(modalStateErrors || serverError || 'Server Error');
        }
      })
    );
  }
}



export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true
};
