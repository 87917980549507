import { Component, OnInit } from '@angular/core';
import { getLocaleDateTimeFormat } from '@angular/common';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat = 40.678178;
  public lng = -73.944158;
  public zoom = 12;
  public year = new Date().getFullYear();
  userSubscription: Subscription;
  user: LoginUser;
  path = '../../../../assets/html/';
  links = [
    ['aboutus.html', 'About Us'],
    ['legalnotice.html', 'Legal Notice'],
    ['privacypolicy.htm', 'Privacy Policy'],
    ['shippingpolicy.html', 'Terms & Conditions'],
  ]

  constructor(private auth: AuthenticationService) { }

  ngOnInit() {
    this.links.map((x: any) => x[0] = this.path + x[0]);

    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  subscribe() { }

}