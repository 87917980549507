// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //jno changing to local host for development
  //remoteApi: 'http://localhost:55135/api',  
  //jno WEB03 api for testing
  //remoteApi: 'https://maxsitemkapi.bslog.com/api',
  //jno prod now on azure api
  remoteApi: 'https://mkapi-prod.azurewebsites.net/api',


  //remoteApi: window.location.protocol + '//localhost:55135/api',
  //  Local Development NEW API
  //   remoteApi: window.location.protocol + '//localhost:56461/api',


  //   adminUrl: 'http://localhost:2525/index.html', // local adminUrl
  // adminUrl: 'https://maxadmin.bslog.com/index.html', // remote dev adminUrl
  adminUrl: 'https://maxsite2020admin.bslog.com/index.html',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
