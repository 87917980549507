import { Component, OnInit, OnDestroy } from '@angular/core';
import { Data, DataService } from '../../../_services/data.service';
import { CartService } from 'src/app/_services/cart.service';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { ApiService } from 'src/app/_services/api-service';
import { UtilityService } from 'src/app/_services/utility.service';
import { ShopCart } from 'src/app/_models/shop-cart';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit, OnDestroy {
  user: LoginUser;
  shopcart: ShopCart;
  userSubscription: Subscription;
  cartSubscription: Subscription;
  adminUrl: string;
  constructor(public dataService: DataService,
    public cartservice: CartService,
    public authService: AuthenticationService,
    private cartService: CartService,
    public utilityService: UtilityService,
    private router: Router,
    private api: ApiService
  ) { }

  ngOnInit() {
    this.userSubscription = this.authService.currentUser.subscribe(user => {
      this.user = user;
      this.adminUrl = environment.adminUrl + '?userid=' + this.user.userID + '&callerid=City#/Home';

    });

    this.cartSubscription = this.cartService.shopcartSubject.subscribe(sc => {
      this.shopcart = sc;
    });
  }

  redirectToAdmin() {
    const url = environment.adminUrl + '?userid=' + this.user.userID + '&callerid=City#/Home';

    // this.router.navigateByUrl(url);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  createMainMenu() {
    this.api.post('Brand/makeCatMenuXML', '')
      .subscribe(retval => {
        this.utilityService.alert('Message from server: ' + retval);
      });
  }

  setEnglish(user: any) {
    user.langPreference = 'English';
    this.api.post('Login/SetLanguage', user).subscribe(retval => {
      //this.utilityService.alert('Successfully updated site language to English.');
      //localStorage.setItem('currentUser', JSON.stringify(user));
      //this.authService.refreshToken();
      user.role = this.utilityService.getUserRole(user); // we convert permissionLevel to a role
      user.language = this.utilityService.getUserLanguage(user); // we convert langPreference to a language
      user.restricted = this.utilityService.getIsRestricted(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      //this.router.navigate(['/']);
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });
  }

  setSpanish(user: any) {
    user.langPreference = 'Spanish';
    this.api.post('Login/SetLanguage', user).subscribe(retval => {
      //this.utilityService.alert('Successfully updated site language to Spanish.');
      //this.authService.refreshToken();
      user.role = this.utilityService.getUserRole(user); // we convert permissionLevel to a role
      user.language = this.utilityService.getUserLanguage(user); // we convert langPreference to a language
      user.restricted = this.utilityService.getIsRestricted(user);
      localStorage.setItem('currentUser', JSON.stringify(user));
      //this.router.navigate(['/']);
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });
  }


}
