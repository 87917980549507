import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';
import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './_helpers/custom-overlay-container';
import { menuScrollStrategy } from './_helpers/scroll-strategy';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
//914
import { SharedModule } from './shared/shared.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { DataService } from './_services/data.service';
import { AppInterceptorProvider } from './_helpers/app-interceptor';
import { ErrorInterceptorProvider } from './_helpers/error.interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { OAuthService, UrlHelperService, OAuthModule } from 'angular-oauth2-oidc';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback/signin-redirect-callback.component';
//import { AuthModule, LogLevel } from 'angular-auth-oidc-client';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    }),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    OAuthModule.forRoot(),
    routing,


  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    LoginComponent,
    SigninRedirectCallbackComponent,
  ],
  providers: [
    AppSettings,
    DataService,
    AppInterceptorProvider,
    ErrorInterceptorProvider,
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
