import { Pipe, PipeTransform } from '@angular/core';
// https://stackblitz.com/edit/angular-5-custom-pipes?file=app%2Fshared%2Fsort.pipe.ts
@Pipe({ name: 'productSort' })
export class ProductSortPipe implements PipeTransform {
  transform(items: any[], field: string, reverse: boolean = false): any[] {
    if (!items) {
      return [];
    }
    if (field === 'name' || field === 'Name' ) {
      items.sort((a, b) => ( a[field] > b[field] ? 1 : -1));
    } else {
      items.sort((a, b) => ( Number(a[field]) > Number(b[field]) ? 1 : -1));
    }
    if (reverse) { items.reverse(); }

    return items;
  }
}