import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-rating-dialog',
  templateUrl: './rating-dialog.component.html',
  styleUrls: ['./rating-dialog.component.scss']
})
export class RatingDialogComponent implements OnInit {
  stars = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];
  closeImg = { cursor: 'pointer', marginLeft: '5px', marginTop: '5px', width: '20px' };
  ratings = ['Not great', 'OK', 'Good', 'Very Good', 'Excellent'];
  msg = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() { }


  onSelect(_i: number) {
    this.reset();
    for (let i = 0; i < this.stars.length; i++) {
      if (_i + 1 > i) {
        this.stars[i] = 'star';
      }
    }
    this.data.Rating = _i + 1;
    this.msg = 'You have rated this product ' + this.ratings[_i] ;
  }
  reset() {
    this.data.Rating = 0;
    this.msg = '';
    this.stars = this.stars.map(s => s = 'star_border');
  }





}
