import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/_services/utility.service';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  // @ViewChild('searchTxt') searchinput: ElementRef;
  userSubscription: Subscription;
  user: LoginUser;
  constructor(private router: Router,
    private utilityService: UtilityService,
    private auth: AuthenticationService) { }

  ngOnInit() {
    this.clearSearch();

    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.user = user;
    });
  }

  public search(evt) {
    // either get the search text from on enter or button pushed
    const val = evt.target ? evt.target.value : evt.value;
    if (val === '') {
      this.utilityService.alert('Please enter search criteria');
    } else {
      this.clearSearch();
      this.router.navigate(['/generic', 0, val]);
    }

  }

  clearSearch() {
    // this.searchinput.nativeElement.value = '';
  }
}
