import { Component, Input, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Product } from '../../_models/product';
import { RatingDialogComponent } from '../rating-dialog/rating-dialog.component';
import { CartService } from 'src/app/_services/cart.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements DoCheck {
  @Input() ratingsCount: number; // reviewCount
  @Input() ratingsValue: number; // reviewRating
  @Input() direction: any;
  @Input() product: Product;
  @Input() showRate: true;
  rating = 0;
  reviewValue: number;
  stars: Array<string>;
  ratinglabel = 'ratings';
  constructor(private dialog: MatDialog,
    private cartService: CartService
  ) { }

  ngDoCheck() {
    if (this.ratingsValue) {
      this.reviewValue = Number(this.ratingsValue); // believe this already is an average;
      this.makeStars();
    }
    this.ratinglabel = Number(this.ratingsCount) === 1 ? ' 1 rating' : this.ratingsCount + ' ratings';
  }

 

  public openDialog() {
    const dialogRef = this.dialog.open(RatingDialogComponent, {
      width: '300px',
      data: { ProductID: this.product.ProductID, Rating: 0 },
      panelClass: 'product-dialog'
    });
    dialogRef.afterClosed().subscribe(retval => {
      if (retval) {

        if (retval.Rating > 0) {
          // this is where we save it:
          this.cartService.addRating(this.product.ProductID, retval.Rating);
          this.ratingsCount ++;
        }

      }
      
    });
  }
  rate(value) {  // When they use dialog to give a rating
    this.rating = Number(value);
  }
  makeStars() {

    switch (this.reviewValue) {
      case 1: {

        this.stars = ['star', 'star_border', 'star_border', 'star_border', 'star_border'];
        break;
      }
      case 2: {

        this.stars = ['star', 'star', 'star_border', 'star_border', 'star_border'];
        break;
      }
      case 3: {

        this.stars = ['star', 'star', 'star', 'star_border', 'star_border'];
        break;
      }
      case 4: {

        this.stars = ['star', 'star', 'star', 'star', 'star_border'];
        break;
      }
      case 5: {

        this.stars = ['star', 'star', 'star', 'star', 'star'];
        break;
      }

      default: {
        this.stars = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];
        break;
      }
    }
  }




}
