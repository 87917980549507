import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';
@Pipe({
  name: 'filterBrands'
})
export class FilterBrandsPipe implements PipeTransform {
  transform(brands: Array<any>, firstLetter?) {
    if (firstLetter === 'all') {
      return brands;
    } else {
      return brands?.filter(
        brand => brand.BrandName.charAt(0).toLowerCase() === firstLetter.toLowerCase()
      );
    }
  }
}

/*
import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'rxjs/operators';

@Pipe({
  name: 'filterBrands'
})
export class FilterBrandsPipe implements PipeTransform {
  transform(brands: Array<any>, firstLetter?) {
    if (firstLetter === 'all') {
      return brands;
    } else {
      const filteredbrands = [];
      brands.map((b: any) => {
        if (b.BrandName.charAt(0).toLowerCase() === firstLetter.toLowerCase()) {
          filteredbrands.push(b);
        }
      });
      return filteredbrands;
    }
  }
}
 */