import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Product } from 'src/app/_models/product';

@Component({
  selector: 'app-detail-dialog',
  templateUrl: 'detail-dialog.component.html',
  styleUrls: ['./detail-dialog.component.scss']
})
export class DetailDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('zoomViewer') zoomViewer;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  isBigImage = false;
  public config: SwiperConfigInterface = {};
  public zoomImage: any;
  private sub: any;
  public relatedProducts: Array<Product>;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialogRef<DetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public p: Product,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.config = {
      observer: false,
      slidesPerView: 4,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 3
        }
      }
    };

  }
  imageLoaded(evt) {
    this.isBigImage = evt.currentTarget.naturalWidth > 800;
  }
  public onMouseMove(e) {

    if (window.innerWidth >= 1280 && this.isBigImage) {
      let image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = (offsetX / image.offsetWidth) * 100;
      y = (offsetY / image.offsetHeight) * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = 'block';
        zoomer.style.height = image.height * 1.5 + 'px';
        zoomer.style.width = image.width * 1.5 + 'px';
      }
    }
  }

  public onMouseLeave(event) {
    // this.zoomViewer.nativeElement.children[0].style.display = 'none';
  }


  public openZoomViewer() {
    /*
    this.dialog.open(ProductZoomComponent, {
      data: this.zoomImage,
      panelClass: 'zoom-dialog'
    });*/
  }
  Savings(val) {
    const numb = Math.round((1 - val.Mileage / val.OldMileage) * 100);
    const retval = numb + '% ' + ' SAVINGS!';
    return retval;
  }
  ngOnDestroy() {

  }


}
