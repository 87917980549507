export class CardData {
  constructor(
    public firstName: string = '',
    public lastName: string = '',
    public UserID: string = '',
    public email: string = '',
    public address: string = '',
    public address1: string = '',
    public state: string = '',
    public city: string = '',
    public zip: string = '',
    public country: string = '',
    public billingAddress: string = '',
    public billingState: string = '',
    public billingCity: string = '',
    public billingZip: string = '',
    public phone: string = '',
    public comments: string = '',
    public number: string = '',
    public name: string = '',
    public xpm: string = '',
    public xpy: string = '',
    public cw: string = '',
    public amt: string = '',
    public total: string = '',
    public lsFirstName: string = '',
    public lsLastName: string = '',
    public lsAddress: string = '',
    public lsAddress1: string = '',
    public lsCity: string = '',
    public lsState: string = '',
    public lsZip: string = '',
    public lsEmail: string = '',
    public lsPhone: string = '',
    public lsCountry: string = '',
  ) { }
}
export class CardDataMK {
  constructor(
    public firstName: string = '',
    public lastName: string = '',
    public UserID: string = '',
    public email: string = '',
    public address: string = '',
    public address1: string = '',
    public state: string = '',
    public city: string = '',
    public zip: string = '',
    public country: string = '',
    public phone: string = '',
    public total: string = ''
  ) { }
}
export class CardDataXX {
  constructor(
    public firstName: string = 'Stephen',
    public lastName: string = 'Vajda',
    public UserID: string = '',
    public email: string = 'sgv@rockwoodpl.com',
    public address: string = '11 Rockwood Pl',
    public address1: string = '',
    public state: string = 'NJ',
    public city: string = 'Edgewater',
    public zip: string = '07020',
    public country: string = 'USA',
    public billingAddress: string = '11 Rockwood Pl',
    public billingState: string = 'NJ',
    public billingCity: string = 'Edgewater',
    public billingZip: string = '07020',
    public phone: string = '201-312-7628',
    public comments: string = '',
    public number: string = '4147202357736212',
    public name: string = 'Stephen G Vajda',
    public xpm: string = '06',
    public xpy: string = '2023',
    public cw: string = '265',
    public amt: string = '',
    public total: string = ''
  ) { }
}
