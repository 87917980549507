import { Injectable } from '@angular/core';

export class Settings {
    constructor(public name: string,
        public theme: string,
        public callerid: string,
        public usecash: boolean,
        public currency: string,
        public logo: string,
        public logoesp: string

    ) { }
}

@Injectable()
export class AppSettings {
    public settings = {
        name: 'Star Boutique', // theme name
        theme: 'maxsite',   // maxsite green, blue, red, pink, purple, grey
        callerid: 'MaryKay2020',  //jno 7/11/2021 was Rymax2020, changing to MaryKay2020 for API constant
        usecash: false,
        currency: 'points', // accepts either dollar or points
        logo: '../../assets/images/logos/marykay-2024-logo-shadow.png',
        logoesp: '../../assets/images/logos/marykay-2024-logo-shadow-esp.png'

    };
}
