import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginUser } from '../_models/loginuser';
import { ShopCart, Prod } from '../_models/shop-cart';
import { ApiService } from './api-service';
import { AuthenticationService } from './authentication.service';
import { UtilityService } from './utility.service';
import { Product } from '../_models/product';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CartService implements OnDestroy {
  shopcartSubject: BehaviorSubject<ShopCart>;
  userSubscription: Subscription;
  canCheckout = true;
  public shopcart: Observable<ShopCart>;
  user: LoginUser;

  constructor(
    private api: ApiService,
    private utilityService: UtilityService,
    private auth: AuthenticationService,
    private router: Router
  ) {
    this.shopcartSubject = new BehaviorSubject<ShopCart>(
      JSON.parse(localStorage.getItem('shopcart'))
    );
    this.shopcart = this.shopcartSubject.asObservable();
    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {

        this.getShopCart();
      }
    });
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
  public get shopcartValue(): ShopCart {
    return this.shopcartSubject.value;
  }
  public get shopcartItemPhrase() {
    const count = this.shopcartSubject.value.shopcartlst.length;
    return count > 1 || count === 0 ? count + ' ITEMS' : count + ' ITEM';
  }
  getShopCart() {
    // https://maxapi.bslog.com/api/Auth/Login
    if (!this.user) {
      return;
    }
    const param = { UserID: this.user.userID };
    return this.api.get('Cart/GetCartWish', param).subscribe(retval => {
      if (retval) {
        const sc = this.cutCart(this.user.userID, retval);
        // store shopcart  in local storage between page refreshes
        localStorage.setItem('shopcart', JSON.stringify(sc));
        this.shopcartSubject.next(sc);
      }
    });
  }

  addCartProduct(p: Product) {
    // https://maxapi.bslog.com/api/Cart/AddCartProduct?ProductID=44561&Qty=1&Status=O&UserID=10009714
    if (this.sameProductInCart(p, this.shopcartSubject.value.shopcartlst)) {
      this.utilityService.alert('This item is in your cart. If you want more than one go to the cart and update it. Thanks!');
      return;
    }
    const param = {
      UserID: this.user.userID,
      ProductID: p.ProductID,
      Status: 'O',
      Qty: '1',
      Href: ''
    };

    return this.api.post('Cart/AddCartProductB', param).subscribe(retval => {
      if (retval) {
        // this.utilityService.alert('Product has been added to your cart');
        this.getShopCart();
        this.router.navigate(['/cart']);
      }
    });
  }

  addWishListProduct(p: Product) {
    // https://maxapi.bslog.com/api/Cart/AddCartProduct?ProductID=44561&Qty=1&Status=O&UserID=10009714
    if (this.sameProductInCart(p, this.shopcartSubject.value.wishlst)) {
      this.utilityService.alertError('This item is already in your wish list.');
      return;
    }
    const param = {
      UserID: this.user.userID,
      ProductID: p.ProductID,
      Status: 'W',
      Qty: '1',
      Href: ''
    };
    return this.api.post('Cart/AddCartProductB', param).subscribe(retval => {
      if (retval) {
        //  this.utilityService.alert('Product has been added to your wish list');
        //jno stay on current page per Sara
        //this.router.navigate(['/wishlist']);
        this.getShopCart();
      }
    });
  }
  addPosterProduct(p: Product) {
    /* if (this.sameProductInCart(p, this.shopcartSubject.value.posterlst)) {
      this.utilityService.alertError('This item is already on your personal STAR poster.');
      return;
    } */
    const param = {
      UserID: this.user.userID,
      ProductID: p.ProductID,
      Status: 'B',
      Qty: '1',
      Href: ''
    };

    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {

      return this.api.post('Cart/AddCartProductB', param).subscribe(retval => {
        if (retval) {
          //this.utilityService.alert('Item has been added to your personal STAR poster.');      
          this.router.navigate(['/wishlistEsp']);
          this.getShopCart();
        }
      });
    }
    else {
      return this.api.post('Cart/AddCartProductB', param).subscribe(retval => {
        if (retval) {
          //this.utilityService.alert('Item has been added to your personal STAR poster.');      
          this.router.navigate(['/wishlist']);
          this.getShopCart();
        }
      });
    }
  }

  clearPosterProducts() {
    const param = {
      UserID: this.user.userID,
      ProductID: 0,
      Status: 'X',
      Qty: '1',
      Href: ''
    };

    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      return this.api.post('Cart/ClearPosterProducts', param).subscribe(retval => {
        if (retval) {
          //this.utilityService.alert('Item has been added to your personal STAR poster.');
          this.router.navigate(['/wishlistEsp']);
          this.getShopCart();
        }
      });
    }
    else {
      return this.api.post('Cart/ClearPosterProducts', param).subscribe(retval => {
        if (retval) {
          //this.utilityService.alert('Item has been added to your personal STAR poster.');
          this.router.navigate(['/wishlist']);
          this.getShopCart();
        }
      });
    }
  }

  addWishProductToCart(p: Product) {
    this.addCartProduct(p);
    this.RemoveWishProduct(p);
  }
  updateCart(p: Prod) {
    // https://maxapi.bslog.com/api/Cart/UpdateCart?ProductID=44561&Qty=2&Status=O&UserID=10009714
    const param = {
      ProductID: p.ProductID,
      Qty: p.qty,
      Status: 'O',
      UserID: this.user.userID
    };
    return this.api.post('Cart/UpdateCartB', param).subscribe(retval => {
      if (retval) {
        this.utilityService.alert('Your cart has been updated');
        this.getShopCart();
      }
    });
  }
  updateWishList(p: any) {
    // https://maxapi.bslog.com/api/Cart/UpdateCart?ProductID=44561&Qty=2&Status=O&UserID=10009714
    const param = {
      ProductID: p.ProductID,
      Qty: p.qty,
      Status: 'W',
      UserID: this.user.userID
    };
    return this.api.post('Cart/UpdateCartB', param).subscribe(retval => {
      if (retval) {
        this.utilityService.alert('Your wish list has been updated');
        this.getShopCart();
      }
    });
  }
  RemoveProduct(p: any) {
    // https://maxapi.bslog.com/api/Cart/DeleteCartProduct?Href=&ProductID=44561&Qty=1&Status=O&UserID=10009714
    const param = {
      ProductID: p.ProductID,
      Qty: 1,
      Status: 'O',
      UserID: this.user.userID
    };
    return this.api.post('Cart/DeleteCartProductB', param).subscribe(retval => {
      if (retval) {
        this.utilityService.alert('Item has been removed from your cart');
        this.getShopCart();
      }
    });
  }
  RemoveWishProduct(p: Product) {
    // https://maxapi.bslog.com/api/Cart/DeleteCartProduct?Href=&ProductID=44561&Qty=1&Status=O&UserID=10009714
    const param = {
      ProductID: p.ProductID,
      Qty: 1,
      Status: 'W',
      UserID: this.user.userID
    };
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      return this.api.post('Cart/DeleteCartProductB', param).subscribe(retval => {
        if (retval) {
          this.utilityService.alert('Item has been removed from your wish list');
          this.router.navigate(['/wishlistEsp']);
          this.getShopCart();
        }
      });
    }
    else {
      return this.api.post('Cart/DeleteCartProductB', param).subscribe(retval => {
        if (retval) {
          this.utilityService.alert('Item has been removed from your wish list');
          this.router.navigate(['/wishlist']);
          this.getShopCart();
        }
      });
    }
  }

  sameProductInCart(_p: Product, list: Prod[]): boolean {
    const sameproducts = list.filter(
      p => p.ProductID === _p.ProductID
    );
    return sameproducts.length > 0;
  }

  cutCart(UserID: string, retval: any): ShopCart {
    const sc: ShopCart = {
      UserID: UserID,
      Total: retval.Total,
      Balance: retval.Balance,
      RestrCnt: retval.RestrCnt,
      BalancePoints: 0,
      AmountDue: '',
      lRestrCnt: '',
      OrderID: retval.OrderID,
      TotalWithShipping: retval.TotalWithShipping,
      BalanceWithShipping: retval.BalanceWithShipping,
      shopcartlst: []
    }; // balance is less than 1 then there is a point balance
    if (Number(sc.Balance) < 1) {
      sc.BalancePoints = Math.abs(Number(sc.Balance));
    }// If more than 0 then cash due
    if (Number(sc.Balance) > 0) {
      sc.AmountDue = sc.Balance;
    }
    if (Number(sc.RestrCnt) > 0) {
      sc.lRestrCnt = sc.RestrCnt;
    }
    sc.shopcartlst = this.addChildren(retval.cartlst);
    sc.wishlst = this.addChildren(retval.wishlst);
    sc.posterlst = this.addChildren(retval.posterlst);
    return sc;
  }
  addChildren(arr: any) {
    const prods: Array<Prod> = [];
    arr.map((p: Prod) => {
      const prod = {
        ProductID: p.ProductID,
        Name: p.Name,
        NameEsp: p.NameEsp,
        imageName: p.imageName,
        Mileage: p.Mileage,
        qty: p.qty,
        subtotal: p.qty * Number(p.Mileage) // use this for the cart page
      };
      prods.push(prod);
    });
    return prods;
  }


  removeCartCookie() {
    localStorage.removeItem('shopcart');
    this.shopcartSubject.next(null);
  }

  addRating(pid: string, r: Number) {
    // https://maxapi.bslog.com/api/Cart/AddRating?ProductID=53987&Rating=3&UserID=10009714
    const param = {
      ProductID: Number(pid), Rating: r, UserID: Number(this.user.userID)
    }; // API doesn't work with param in body
    this.api.post('Cart/AddRating?ProductID=' + pid + '&Rating=' + r + '&UserID=' + this.user.userID, param)
      .subscribe((retval: any) => {
        this.utilityService.alert(retval);
      });

  }

  decrement(p: Prod) {
    if (p.qty < 2) {  // if 1 item we remove it--less than 1 won't happen but who knows?
      this.RemoveProduct(p);
    } else {
      p.qty--;
      this.updateCart(p);
    }
  }
  increment(p: Prod) {
    p.qty++;
    this.updateCart(p);
  }
}
