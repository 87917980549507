import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {
  @Input() mnu: any;
  @ViewChild('parentMenu', { static: true }) public parentMenu;
  constructor(private router: Router) {}

  ngOnInit() {}

  menuClicked(p: any){
      //  [routerLink]="['/generic', p.id, p.name]"
      if (window.innerWidth > 960) {
        this.router.navigate(['/generic', p.id, p.name]);
      }
  }
 
}
