import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { AppSettings } from 'src/app/app.settings';


@Pipe({
  name: 'price'
})
export class PricePipe extends CurrencyPipe implements PipeTransform {
  transform(value?: any, type?: any, withlabel: boolean = true): any {
    switch (this.appSettings.settings.currency) {
      case 'points':
        const retval = value * 100 / 100;
        var usrobj = JSON.parse(localStorage.getItem('currentUser'));
        if (usrobj.langPreference === 'Spanish') {
          return withlabel === true ? retval.toLocaleString() + ' Creditos' : retval.toLocaleString();
        }
        else {
          return withlabel === true ? retval.toLocaleString() + ' Credits' : retval.toLocaleString();
        }
        break;
      case 'dollar': {
        const cp: CurrencyPipe = new CurrencyPipe('en-US');
        return cp.transform(value, 'USD');

      }

    }
    return null;
  }

  /**
   *
   */
  constructor(private appSettings: AppSettings) {
    super(`en-US`);
  }


}
