import { Component, ViewEncapsulation, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {Order} from '../../_models/order';
/* this dialog is used by the recognition/pointhistory component to display a list of orders*/
@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderDialogComponent implements OnInit, AfterViewInit {
  public config: SwiperConfigInterface = {};
 
  constructor(
    public dialogRef: MatDialogRef<OrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public order: Order
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
   
    this.config = {
     slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    };
  }

  public close(): void {
    this.dialogRef.close();
  }
}
