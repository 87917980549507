import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { UserManager, User } from 'oidc-client';
//import { Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { getLocaleDateFormat } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { UtilityService } from '../_services/utility.service';
import { first } from 'rxjs/operators';
import { OAuthService, OAuthModule, AuthConfig, OAuthEvent } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { delay } from 'q';

@Component({
  selector: 'app-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class SigninRedirectCallbackComponent implements OnInit {
  //private _userManager: UserManager;
  //private _user: User;
  //private sub: Subscription;
  submitted = false;
  loading = false;
  returnUrl: string;
  userinfo: any;

  config: any;
  //userData$: Observable<UserDataResult> | undefined;
  isAuthenticated = false;
  token: any;

  userdata: any;

  constructor(
    public oidcSecurityService: OAuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private httpclient: HttpClient,
    private msAuth: AuthenticationService,
    private utilityService: UtilityService,
  ) { }


  ngOnInit() {
    this.utilityService.getEncryptKey();

    //this.userinfo = this.oidcSecurityService.loadUserProfile();
    console.log('Access Token!!!');
    setTimeout(() => {
      this.token = this.oidcSecurityService.getAccessToken();
      console.log(this.token);
      this.getUserData();
      //console.log(this.userinfo);
    }, 2400);
  }

  getUserData() {
    localStorage.setItem('mksso', 'Yes');
    var config: any;
    localStorage.setItem('mksso', 'No');
    this.httpclient.post('https://mkapi-proxy.azurewebsites.net/api/proxy', {

      "targetUrl": "https://usa.marykayapi.com/us/Partners/Rymax/consultantQuarterStarProduction",
      "token": this.token

    }).subscribe(
      data => {
        console.log('get response');
        console.log(data);
        this.userdata = data;
        //config = data;

        this.msAuth.loginAuto(this.userdata.ConsultantNumber, this.userdata.ContestTotal, this.userdata.SubsidiaryCode, this.userdata.ContestCode, this.userdata.StarCount, this.userdata.BWHFlag, this.userdata.ContestSubCode).toPromise().then(data => {

          this.router.navigate(['/']);

          //this.router.navigate(['/']);
        }, error => {
          this.utilityService.alertError(error);
          this.loading = false;
        }
        );

      }
    )


  }

}
