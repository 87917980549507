import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/_models/product';
import { ProductService } from 'src/app/_services/product.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';

@Component({
  selector: 'app-product-detail-list',
  templateUrl: './product-detail-list.component.html',
  styleUrls: ['./product-detail-list.component.scss']
})
export class ProductDetailListComponent implements OnInit {
  @Input('product') p: Product;
  private userSub: Subscription;
  user: LoginUser;
  constructor(public productService: ProductService, private auth: AuthenticationService) { }

  ngOnInit() {
    this.userSub = this.auth.currentUser.subscribe((user: any) => {
      this.user = user;
    });
  }

}
