import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogComponent } from './product-dialog/product-dialog.component';
import { DataService } from '../../_services/data.service';
import { Product } from '../../_models/product';
import { UtilityService } from 'src/app/_services/utility.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';

import {
  SwiperConfigInterface,
  SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import { ProductService } from 'src/app/_services/product.service';
@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss']
})
export class ProductsCarouselComponent implements OnInit, AfterViewInit {
  @Input('products') products: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  private userSub: Subscription;
  user: LoginUser;

  constructor(
    public dataService: DataService,
    public dialog: MatDialog,
    private router: Router,
    public utilityService: UtilityService,
    public productService: ProductService,
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    this.userSub = this.auth.currentUser.subscribe((user: any) => {
      this.user = user;
    });
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 10,
      autoHeight: false,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    };
  }

  public openProductDialog(product: Product) {
    const dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: 'product-dialog'
    });
    dialogRef.afterClosed().subscribe(_product => {
      if (product) {
        this.router.navigate(['/products', _product.ProductID, _product.Name]);
      }
    });
  }
}
