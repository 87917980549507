import { Component, ViewEncapsulation, AfterViewInit, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CardData } from 'src/app/_models/card-data';
import { LoginUser } from 'src/app/_models/loginuser';


@Component({
  selector: 'app-checkout-dialog',
  templateUrl: './checkout-dialog.component.html',
  styleUrls: ['./checkout-dialog.component.scss']
})
export class CheckoutDialogComponent implements OnInit {
  useShippingAddress = true;
  constructor(
    public dialogRef: MatDialogRef<CheckoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  public data: CardData
  ) {}

  ngOnInit() {
  }
  toggle() {
    this.useShippingAddress = !this.useShippingAddress;

  }
}
