import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterByIdPipe } from './filter-by-id.pipe';
import { FilterBrandsPipe } from './filter-brands.pipe';
import { BrandSearchPipe } from './brand-search.pipe';
import { ProductSearchPipe } from './product-search.pipe';
import {ProductSortPipe} from './product-sort.pipe';
import { PricePipe } from './price.pipe';
@NgModule({
   imports: [
      CommonModule
   ],
   declarations: [
      FilterByIdPipe,
      FilterBrandsPipe,
      BrandSearchPipe,
      ProductSearchPipe,
      ProductSortPipe,
      PricePipe
   ],
   exports: [
      FilterByIdPipe,
      FilterBrandsPipe,
      BrandSearchPipe,
      ProductSearchPipe,
      ProductSortPipe,
      PricePipe
   ]
})
export class PipesModule {}
