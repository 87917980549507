import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api-service';
import {
  SwiperConfigInterface,
  SwiperPaginationInterface
} from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-badge-carousel',
  templateUrl: './badge-carousel.component.html',
  styleUrls: ['./badge-carousel.component.scss']
})
export class BadgeCarouselComponent implements OnInit, AfterViewInit {
  badges: any;
  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.getBadges();
  }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: 'slide',
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        },
        1500: {
          slidesPerView: 5
        }
      }
    };
  }

  getBadges() {
    // http://localhost:55135/api/Peer/GetBadges
    this.apiService.get('Peer/GetBadges')
      .subscribe(retval => {
        this.badges = this.mapBadgeImage(retval);
      });

  }

  mapBadgeImage(badges: any) { // data contains path which is diffeent here.
    // ../../../../../assets/images/recognition/badges/COLLABORATION.png
    const path = '../../../../../assets/images/recognition/badges/';
    badges.map(b => {
      b.Image = b.Image.toUpperCase().replace('.PNG', '');
      const arr = b.Image.split('/');
      b.Image = path + arr[3].toUpperCase() + '.png';
     

    });
    return badges;
  }


}
