import { Component, OnInit, Input } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ApiService } from 'src/app/_services/api-service';
import { MenuService } from 'src/app/_services/menu.service';
import { LoginUser } from 'src/app/_models/loginuser';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DataService } from 'src/app/_services/data.service';


@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: []
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  user: LoginUser;
  showSearch = false;
  userSubscription: Subscription;
  pointRange = new Array<any>();
  gp = new Array<any>();
  showCategories = false;

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  constructor(
    private api: ApiService,
    private menuService: MenuService,
    private dataService: DataService,
    private auth: AuthenticationService) {
    this.dataSource.data = TREE_DATA;
  }

  ngOnInit() {
    // this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    // this.getCategories();
    this.gp = this.menuService.gp;
    this.userSubscription = this.auth.currentUser.subscribe((user: any) => {
      this.user = user;
    });

    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      this.dataService.getJSON('point-range-mk-esp').subscribe(retval => {
        this.pointRange = retval;
      });
    }
    else {
      this.dataService.getJSON('point-range-mk').subscribe(retval => {
        this.pointRange = retval;
      });
    }

  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }


  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.itms && node.itms.length > 0,
      name: node.name,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this.transformer, node => node.level, node => node.expandable, node => node.itms);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);



}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
//
const TREE_DATA: FoodNode[] = [
  {
    name: 'All Products',
    link: 'hellow',
  },
  {
    name: 'Fruit',
    itms: [
      { name: 'Apple' },
      { name: 'Banana' },
      { name: 'Fruit loops' },
    ]
  }, {
    name: 'Vegetables',
    itms: [
      {
        name: 'Green',
        itms: [
          { name: 'Broccoli' },
          { name: 'Brussel sprouts' },
        ]
      }, {
        name: 'Orange',
        itms: [
          { name: 'Pumpkins' },
          { name: 'Carrots' },
        ]
      },
    ]
  },
];
interface FoodNode {
  name: string;
  link?: string;
  itms?: FoodNode[];
}