import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input('banners') banners: Array<any> = [];
  // "../../../assets/images/categories/shop-category-fitness.jpg"
  private imagepath = '../../../assets/images/banners/';
  constructor(private router: Router) {
    
  }

  ngOnInit() { }

  public getBanner(index) {
    return this.banners[index];
  }

  showProducts(idx) {
    const val = this.banners[idx].title;
    const key = '00';
    this.router.navigate(['/generic', key, val]);
  }
  public getBgImage(index) {
    const bgImage = {
      'background-image': index != null ? 'url(' + this.imagepath + this.banners[index].image + ')' : 'url(https://via.placeholder.com/600x400/ff0000/fff/)'
    };
    return bgImage;
  }
  

}
