import { Injectable } from '@angular/core';
import { Product } from '../_models/product';
import { Router } from '@angular/router';
import { LoginUser } from '../_models/loginuser';
import { ApiService } from './api-service';
import { UtilityService } from './utility.service';
import { DetailDialogComponent } from '../shared/detail-dialog/detail-dialog.component';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { CartService } from './cart.service';
import { ShopCart } from '../_models/shop-cart';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  selectedProduct: Product;
  // private viewedProdsSubject: BehaviorSubject<string>[];
  viewedProdsSubject = new BehaviorSubject<Array<Product>>([]);
  public viewedProds = new Array<number>();
  viewedProducts = new Array<Product>();
  user: LoginUser;
  userSubscription: Subscription;
  cartProdIdArr = [];

  cartSubscription: Subscription;
  constructor(private router: Router,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private utilityService: UtilityService,
    private cartService: CartService,
    private api: ApiService) {
    this.userSubscription = this.auth.currentUser.subscribe((user: any) => {
      this.user = user;
    });

    this.cartService.shopcartValue.shopcartlst.map(p => this.cartProdIdArr.push(Number(p.ProductID)));
    // Gets the viewed products id list cookie and get the corresponding products
    const temp = localStorage.getItem('viewedProds');
    if (temp) {
      this.viewedProds = JSON.parse(temp);
      this.getViewedProducts();
    }

    this.cartSubscription = this.cartService.shopcartSubject.subscribe(sc => {
      const shopcart: ShopCart = sc;
      this.cartProdIdArr = [];
      this.cartService.shopcartValue.shopcartlst.map(p => this.cartProdIdArr.push(Number(p.ProductID)));
      this.removeCartProducts();
    });
  }
  public openDialogProductByProdID(id: string) {
    this.api
      .get('Product/GetProductByProductId', { productID: id, UserID: this.user.userID })
      .subscribe((retval: any) => {
        if (retval) {
          this.openDialogProduct(retval);
        }

      }, error => {
        this.utilityService.alertError('Sorry, this item is no longer available');
      }
      );
  }

  public getProductByProdID(id: string) {
    return this.api.get('Product/GetProductByProductId', { productID: id, UserID: this.user.userID });
  }

  public getPromoItemByProdID(id: string) {
    return this.api.get('Product/GetPromoItemByProductId', { productID: id, UserID: this.user.userID });
  }

  public getCurrentPromotion() {
    return this.api.get('Product/GetCurrentPromotion', { UserID: this.user.userID });
  }

  public openDialogProduct(p: Product) {
    this.router.navigate(['/products', p.ProductID, p.Name]);
    this.selectedProduct = p;
    this.addToViewedProducts();

  }

  public openDialogProductOld(p: Product) {
    const dialogRef = this.dialog.open(DetailDialogComponent, {
      data: p,
      panelClass: 'product-dialog'
    });
    dialogRef.afterClosed().subscribe(retval => {

    });
  }


  removeCartProducts() {
    for (let i = 0; i < this.cartProdIdArr.length; i++) {
      const cartid = Number(this.cartProdIdArr[i]);
      for (let x = 0; x < this.viewedProds.length; x++) {
        const prodid = Number(this.viewedProds[x]);
        if (cartid === prodid) {
          this.viewedProds.splice(x, 1);
          this.refreshScreen(cartid);
        }
      }
    }
    this.refreshViewedProductsStorage();
    this.getViewedProducts();
  }

  private refreshScreen(cartid: Number) {
    for (let i = 0; i < this.viewedProducts.length; i++) {
      this.viewedProducts.splice(i, 1);
    }
  }
  // Viewed products handling
  addToViewedProducts() {
    const id = Number(this.selectedProduct.ProductID);
    // we don't add articles in the shopping cart
    if (this.cartProdIdArr.includes(id)) {
      return;
    }
    const prods = this.viewedProds;

    if (prods == null) {
      return;
    }

    if (prods.length > 0) {
      if (!prods.includes(id)) { // if we have view products and its not already there we add to the beginning.
        prods.unshift(id);
      }
    } else { // else we just add it
      prods.push(id);
    }

    if (prods.length > 4) { // if more than 4 viewed products we make it 4
      prods.length = 4;
    }
    this.refreshViewedProductsStorage();
    this.getViewedProducts();
  }
  public refreshViewedProductsStorage() {
    localStorage.removeItem('viewedProds');
    localStorage.setItem('viewedProds', JSON.stringify(this.viewedProds));
  }

  public getViewedProducts() {
    return this.api.get('Search/GetGroupOfProducts', { productids: this.viewedProds.toString(), userid: this.user.userID })
      .subscribe((prods: any) => {
        this.viewedProducts = prods;
        this.viewedProdsSubject.next(this.viewedProducts);
      });
  }
}
