import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from 'src/app/_services/api-service';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/_services/utility.service';
import { MenuService } from 'src/app/_services/menu.service';
import { DataService } from 'src/app/_services/data.service';
import { Subscription } from 'rxjs';
import { LoginUser } from 'src/app/_models/loginuser';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  catmenu = new Array<any>();
  pointRange = new Array<any>();
  userSubscription: Subscription;
  user: LoginUser;
  btnSearchBy: any;
  constructor(private api: ApiService,
    private cdr: ChangeDetectorRef,
    private utilityService: UtilityService,
    private dataService: DataService,
    private menuService: MenuService,
    private auth: AuthenticationService,) {
    this.catmenu = this.menuService.gp;
  }

  ngOnInit() {
    // this.getCategories();
    this.cdr.detectChanges();

    this.userSubscription = this.auth.currentUser.subscribe(user => {
      this.user = user;
    });

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      document.getElementById("btnSearchBy").innerText = "Buscar por créditos";
      this.dataService.getJSON('point-range-mk-esp').subscribe(retval => {
        this.pointRange = retval;
      });
    }
    else {
      document.getElementById("btnSearchBy").innerText = "Search By Credits";
      this.dataService.getJSON('point-range-mk').subscribe(retval => {
        this.pointRange = retval;
      });
    }
  }

  getCategories() {
    const url = 'Brand/GetCategoriesMenuXML';

    this.api
      .get(url)
      .pipe(map((retval: any) => JSON.parse(retval)))
      .subscribe((retval: any) => {
        // console.log(retval);
        let tmpparents: any;
        for (let a = 0; a < retval.Categories.GrandParent.length; a++) {
          const tmp = retval.Categories.GrandParent[a];
          const gp: MnuItm = {
            name: tmp['@GPname'],
            id: tmp['@GPID'],
            itms: []
          };
          gp.itms.push({ name: 'All ' + gp.name, id: gp.id, itms: [] });
          // menu items with more than 1 parents have different hierarchy
          if (tmp.Parents.length !== undefined) {
            tmpparents = tmp.Parents;
            for (let b = 0; b < tmpparents.length; b++) {
              const p = tmpparents[b];
              p.name = p['@Pname'];
              p.id = p['@PID'];
              p.itms = this.ChangeFldName(p.Child);
              gp.itms.push(p);
            }// menu items with only 1 parent
          } else {
            const newp = {
              name: tmp['Parents']['@Pname'],
              id: tmp['Parents']['@PID'],
              itms: []
            };
            // console.log(newp.name);
            for (let s = 0; s < tmp['Parents'].Child.length; s++) {
              const ccc = tmp['Parents'].Child[s];
              newp.itms.push({ name: ccc.Name, id: ccc.ID });
            }
            // console.log(newp.itms)
            gp.itms.push(newp);
          }

          this.catmenu.push(gp);
          // const gc: MnuItm = { name: 'Gift Cards', id: '30150', itms: [] };
          //  gc.itms.push({ name: 'All ' + gc.name, id: gc.id, itms: [] });
          // this.catmenu.push(gc);
        }
      });

    // localStorage.setItem('menu', JSON.stringify(this.catmenu));
  }

  /* getCategoriesEsp() {
    const url = 'Brand/GetCategoriesMenuXMLEsp';
    this.api
      .get(url)
      .pipe(map((retval: any) => JSON.parse(retval)))
      .subscribe((retval: any) => {
        // console.log(retval);
        let tmpparents: any;
        for (let a = 0; a < retval.Categories.GrandParent.length; a++) {
          const tmp = retval.Categories.GrandParent[a];
          const gp: MnuItm = {
            name: tmp['@GPname'],
            id: tmp['@GPID'],
            itms: []
          };
          gp.itms.push({ name: 'All ' + gp.name, id: gp.id, itms: [] });
          // menu items with more than 1 parents have different hierarchy
          if (tmp.Parents.length !== undefined) {
            tmpparents = tmp.Parents;
            for (let b = 0; b < tmpparents.length; b++) {
              const p = tmpparents[b];
              p.name = p['@Pname'];
              p.id = p['@PID'];
              p.itms = this.ChangeFldName(p.Child);
              gp.itms.push(p);
            }// menu items with only 1 parent
          } else {
            const newp = {
              name: tmp['Parents']['@Pname'],
              id: tmp['Parents']['@PID'],
              itms: []
            };
            for (let s = 0; s < tmp['Parents'].Child.length; s++) {
              const ccc = tmp['Parents'].Child[s];
              newp.itms.push({ name: ccc.Name, id: ccc.ID });
            }
            gp.itms.push(newp);
          }
          this.catmenu.push(gp);
        }
      });
  } */

  DebugCatMenu() {
    for (let a = 0; a < this.catmenu.length; a++) {
      const gp = this.catmenu[a];
      console.log('', gp.name);

      for (let b = 0; b < gp.itms.length; b++) {
        const p = gp.itms[b];
        console.log('*****', p.name);

        for (let c = 0; c < p.itms.length; c++) {
          const child = p.itms[c];
          console.log('***************', child.name);
        }
      }
    }
  }

  ChangeFldName(arr) {
    const retval = new Array<SubMnuItm>();
    arr.map(x => {
      const c: any = { name: '', id: '' };
      c.name = x.Name;
      c.id = x.ID;
      retval.push(c);
    });
    return retval;
  }

  getCategoriesMenuOld() {
    const url = 'Brand/GetCategoriesMenuXML';
    this.api.get(url).subscribe((retval: any) => {
      this.catmenu = retval.Categories;
    });
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }
}

export class SubMnuItm {
  name: string;
  id: string;
  itms: SubMnuItm[];
}
export class MnuItm {
  name: string;
  id: string;
  itms: any;
}
export class Kvp {
  name: string;
  id: string;
}

/*
getCategories() {
    const url = 'Brand/GetCategoriesMenuXML';
    this.api
      .get(url)
      .pipe(map((retval: any) => JSON.parse(retval)))
      .subscribe((retval: any) => {
        // console.log(retval);
        let tmpparents: any;
        for (let a = 0; a < retval.Categories.GrandParent.length; a++) {
          const tmp = retval.Categories.GrandParent[a];
          const gp: MnuItm = {
            name: tmp['@GPname'],
            id: tmp['@GPID'],
            itms: []
          };
          // menu items with more than 1 parents have different hierarchy
          if (tmp.Parents.length !== undefined) {
            tmpparents = tmp.Parents;
            for (let b = 0; b < tmpparents.length; b++) {
              const p = tmpparents[b];
              p.name = p['@Pname'];
              p.id = p['@PID'];
              p.itms = this.ChangeFldName(p.Child);
              gp.itms.push(p);
            }// menu items with only 1 parent
          } else {
            const newp = {
              name: tmp['Parents']['@Pname'],
              id: tmp['Parents']['@PID'],
              itms: []
            };
            // console.log(newp.name);
            for (let s = 0; s < tmp['Parents'].Child.length; s++) {
              const ccc = tmp['Parents'].Child[s];
              newp.itms.push({ name: ccc.Name, id: ccc.ID });
            }
            // console.log(newp.itms)
            gp.itms.push(newp);
          }

          this.catmenu.push(gp);
        }
       });
  }
  DebugCatMenu() {
    for (let a = 0; a < this.catmenu.length; a++) {
      const gp = this.catmenu[a];
      console.log('', gp.name);

      for (let b = 0; b < gp.itms.length; b++) {
        const p = gp.itms[b];
        console.log('*****', p.name);

        for (let c = 0; c < p.itms.length; c++) {
          const child = p.itms[c];
          console.log('***************', child.name);
        }
      }
    }
  }
*/
