import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match';
// http://jasonwatmore.com/post/2018/11/07/angular-7-reactive-forms-validation-example
@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {
  passwordForm: FormGroup;
  f: any; // a shortcut
  password = '';
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      'confirmPassword': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(3)]]
    }, {
      validator: MustMatch('password', 'confirmPassword')
  });
  // a shortcut
  this.f = this.passwordForm.controls;
}

}
