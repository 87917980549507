import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'productSearchPipe', pure: false })
export class ProductSearchPipe implements PipeTransform {
  transform(products, args?): Array<any> {
    const searchText = new RegExp(args, 'ig');
   
    if (products) {
     return products.filter(product => {
        if (product.Name) {
          return product.Name.search(searchText) !== -1;
        }
      });
    }
  }
}
