import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs/Observable';
//914
import 'rxjs/add/operator/do';
import { AuthenticationService } from '../_services/authentication.service';
import { AppSettings } from '../app.settings';
import { UtilityService } from '../_services/utility.service';
@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private spinner: NgxSpinnerService,
    private authenticationService: AuthenticationService,
    private app: AppSettings,
    private utilityService: UtilityService
  ) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.utilityService.spinner.show();
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    if (!request.url.includes('marykay')) {
      if (currentUser && currentUser.access_token) {  // fully authenticated user from local storage
        console.log(request.url);
        request = request.clone({
          setHeaders: {
            //jno - appid and Authorization headers are removed to reach MK Login SSO - will need later for MaxSite login
            //jno - NEEDS conditional to have available for MaxSite http requests but different for MK SSO
            appid: this.app.settings.callerid, // `Bearer ${localStorage.getItem('token')}`
            Authorization: `Bearer ${currentUser.access_token}`
          }
        });


      } else {
        console.log(request.url);// otherwise just add the app id to allow login
        request = request.clone({
          setHeaders: {
            appid: this.app.settings.callerid
          }
        });
      }
    }


    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    }
    );
  }
}


export const AppInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AppInterceptor,
  multi: true
};