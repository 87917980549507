import { Injectable } from '@angular/core';
import { ApiService } from './api-service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  gp = new Array<any>();
  constructor(private api: ApiService) {
    //this.getCategories();
    var usrobj = JSON.parse(localStorage.getItem('currentUser'));
    if (usrobj.langPreference === 'Spanish') {
      this.getCategoriesEsp();
    } else {
      this.getCategories();
    };
  }

  getCategories() {
    const url = 'generic/GetCategories';
    this.api.get(url)
      .subscribe((retval: any) => {
        this.makeTree(retval);
      });
  }

  getCategoriesEsp() {
    const url = 'generic/GetCategoriesEsp';
    this.api.get(url)
      .subscribe((retval: any) => {
        this.makeTree(retval);
      });
  }

  makeTree(cats: any) {
    const parents = new Array<any>();
    const itms = new Array<any>();
    cats.map(x => {  // split it first
      if (x.id % 1000 === 0) {
        this.gp.push(x);
      } else {
        x.id % 100 === 0 ? parents.push(x) : itms.push(x);
      }
    });
    // then put it back together--this uses the parent id which more accurate than below.
    this.gp.map(c => {
      c.itms = parents.filter(x => x.pid.toString() === c.id.toString());
      c.itms.unshift({ name: 'All ' + c.name, id: c.id, itms: [] }); // Add a clickable link to the parents.    
      c.itms.map(x => x.itms = itms.filter(xx => xx.pid.toString() === x.id.toString()));
    });



    // then put it back together
    /*
    this.gp.map(c => {
      c.itms = parents.filter(x => x.id.toString().substring(0, 2) === c.id.toString().substring(0, 2));
      c.itms.unshift({ name: 'All ' + c.name, id: c.id, itms: [] }); // Add a clickable link to the parents.    
      c.itms.map(x => x.itms = itms.filter(xx => xx.id.toString().substring(0, 3) === x.id.toString().substring(0, 3)));

    });*/

  }

}
