import { Injectable, OnInit } from '@angular/core';
import { Branddata } from '../_models/branddata';
import { Slide } from '../_models/slide';
import { ApiService } from './api-service';
import CryptoJS from 'crypto-js';
import { Product } from '../_models/product';
import { ConfirmModalComponent } from '../shared/confirm-modal/confirm-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { LoginUser } from '../_models/loginuser';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationEnd, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UtilityService implements OnInit {
  encryptKey: string;
  catmenu = new Array<any>();
  history = [];
  validators = {
    email: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    zip: /(^\d{5}$)|(^\d{5}-\d{4}$)/
  };
  //914
  ngOnInit() {
    this.getEncryptKey();
  }
  constructor(private api: ApiService,
    public dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    public spinner: NgxSpinnerService) {
    // https://blog.hackages.io/our-solution-to-get-a-previous-route-with-angular-5-601c16621cf0
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
      });
  }
  public getPreviousUrl() {
    return this.history[this.history.length - 2 || '/index'];
  }

  public getHistory(): string[] {
    return this.history;
  }

  sliceCC(cc: any) {
    return cc.slice(cc.substring.length - 6);

  }
  minmaxRange(products: Product[]) {
    const retval = { min: 0, max: 0 };
    // set the start values
    retval.min = Number(products[0].Mileage);
    retval.max = retval.min;
    products.map(p => {
      const m: number = Number(p.Mileage);
      if (m < retval.min) {
        // MIN
        retval.min = m;
      }
      if (m > retval.max) {
        // MAX
        retval.max = m;
      }
    });
    return retval;
  }
  Savings(val) {
    const numb = Math.round((1 - val.Mileage / val.OldMileage) * 100);
    const retval = numb + '% ' + ' SAVINGS!';
    return retval;
  }
  BrandDataToSlide(b: Branddata) {
    const slides = new Array<Slide>();
    for (let i = 0; i < b.imglst.length; i++) {
      const img = b.imglst[i];
      const s: Slide = {
        title: img.caption,
        subtitle: img.caption,
        image: img.file
      };
      slides.push(s);
    }
    return slides;
  }

  getEncryptKey() {
    // change this back to local later => api/Util/GetEncryptKey
    const url = 'Login/GetEncryptKey';
    return this.api.get(url).subscribe((response: any) => {
      this.encryptKey = response.toString();
    });
  }
  encrypt(val): string {
    if (this.encryptKey === undefined) {
      this.getEncryptKey();
    }
    // https://www.npmjs.com/package/crypto-js
    // https://sirsaula.wordpress.com/2017/08/02/ionic-angular-2-aes-encryption/
    const key = CryptoJS.enc.Utf8.parse(this.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptKey);
    const encrypted = CryptoJS.AES.encrypt(val, key, {
      iv: iv,
      keySize: 128 / 8,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted;
  }

  decrypt(val: string) {
    if (this.encryptKey === undefined) {
      this.getEncryptKey();
    }
    const key = CryptoJS.enc.Utf8.parse(this.encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(this.encryptKey);
    const secret = CryptoJS.AES.decrypt(CryptoJS.enc.Utf8.parse(val), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return secret;
  }
  currentYear() {
    const yr = new Date().getFullYear();
    return yr;
  }
  shortToday(): string {
    const d = new Date();
    const m = +d.getMonth();
    const mths = [
      'Jan',
      'Feb',
      'Mar',
      'April',
      'May',
      'June',
      'July',
      'Aug',
      'Sept',
      'Oct',
      'Nov',
      'Dec'
    ];
    const mth = mths[m];
    const out = mth + ' ' + d.getDate() + ', ' + d.getFullYear();
    return out;
  }
  openDialog(msg?: string): Observable<any> {
    // https://medium.com/@nacojohn/implement-angular-material-dialog-in-your-project-486c7c2f7f5d
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      message:
        (!msg) ? 'Please confirm you intend to make this change' : msg
    };
    const dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
    return dialogRef.afterClosed();
  }


  alert(msg: string) {
    this.snackBar.open(msg, 'x', { panelClass: 'success', verticalPosition: 'bottom', duration: 4000 });
  }
  alertError(msg: string) {
    this.snackBar.open(msg, 'x', { panelClass: 'error', verticalPosition: 'bottom', duration: 4000 });
  }
  alertMK(msg: string) {
    this.snackBar.open(msg, 'X', { panelClass: 'successMK', verticalPosition: 'top' });
  }
  alertErrorMK(msg: string) {
    this.snackBar.open(msg, 'X', { panelClass: 'errorMK', verticalPosition: 'top' });
  }

  getCategories() {
    const url = 'Brand/GetCategoriesMenuXML';
    this.api
      .get(url)
      .pipe(map((retval: any) => JSON.parse(retval)))
      .subscribe((retval: any) => {
        // console.log(retval);
        let tmpparents: any;
        for (let a = 0; a < retval.Categories.GrandParent.length; a++) {
          const tmp = retval.Categories.GrandParent[a];
          const gp: MnuItm = {
            name: tmp['@GPname'],
            id: tmp['@GPID'],
            itms: []
          };
          gp.itms.push({ name: 'All ' + gp.name, id: gp.id, itms: [] });
          // menu items with more than 1 parents have different hierarchy
          if (tmp.Parents.length !== undefined) {
            tmpparents = tmp.Parents;
            for (let b = 0; b < tmpparents.length; b++) {
              const p = tmpparents[b];
              p.name = p['@Pname'];
              p.id = p['@PID'];
              p.itms = this.ChangeFldName(p.Child);
              gp.itms.push(p);
            }// menu items with only 1 parent
          } else {
            const newp = {
              name: tmp['Parents']['@Pname'],
              id: tmp['Parents']['@PID'],
              itms: []
            };
            // console.log(newp.name);
            for (let s = 0; s < tmp['Parents'].Child.length; s++) {
              const ccc = tmp['Parents'].Child[s];
              newp.itms.push({ name: ccc.Name, id: ccc.ID });
            }
            // console.log(newp.itms)
            gp.itms.push(newp);
          }

          this.catmenu.push(gp);
        }
      });
  }
  ChangeFldName(arr) {
    const retval = new Array<SubMnuItm>();
    arr.map(x => {
      const c: any = { name: '', id: '' };
      c.name = x.Name;
      c.id = x.ID;
      retval.push(c);
    });
    return retval;
  }
  getUserRole(user: LoginUser) {
    let retval = 'user';
    if (Number(user.permissionLevel) > 1) {
      retval = 'admin';
    }
    if (user.userID === '10009714') {
      retval = 'admin';
    }
    return retval;
  }
  getUserLanguage(user: LoginUser) {
    let retval = 'English';
    if (user.langPreference === 'Spanish') {
      retval = 'Spanish';
    }
    return retval;
  }
  getIsRestricted(user: LoginUser) {
    let retval = false;
    if (user.IsRestricted) {
      retval = true;
    }
    return retval;
  }

  /* setLanguage(uid: string, prefLang: string) {
    const param = {
      UserID: uid,
      Lang: prefLang
    };
    return this.api.post('Login/SetLanguage', param).subscribe(retval => {
      if (retval) {
        //reset the component with menu
        this.currentUserSubject.next(user);
      }
    });
  } */

  /* setLanguage(user: LoginUser) {
    const param = {
      UserID: uid,
      Lang: prefLang
    };
    return this.api.post('Login/SetLanguage', param).subscribe(retval => {
      if (retval) {
        //reset the component with menu
        this.currentUserSubject.next(user);
      }
    });
  } */

  getUserRoleOld(user: LoginUser) {
    let retval = '';
    switch (Number(user.permissionLevel)) {
      case 1:
        retval = 'user';
        break;
      case 2:
      case 2:
      case 3:
      case 4:
        retval = 'admin';
        break;
    }
    if (user.userID === '10009714') {
      retval = 'admin';
    }
    return retval;
  }
}
export class SubMnuItm {
  name: string;
  id: string;
  itms: SubMnuItm[];
}
export class MnuItm {
  name: string;
  id: string;
  itms: any;
}
export class Kvp {
  name: string;
  id: string;
}